import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
import router from '@/router';
import { downloadData } from '@/utils/window'
var qs = require('qs')
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
})

// 请求拦截
service.interceptors.request.use(
  config => {
    config.data = qs.stringify(config.data)
    if (store.getters.token) {
      config.headers['token'] = getToken()
    }
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)
// 响应拦截
service.interceptors.response.use(
  response => {
    const { data, headers } = response
    if (headers['content-type'] === 'application/octet-stream') {
      downloadData(data, decodeURI(headers['content-name']))
      return true
    }
    if (headers['content-type'] === 'application/octet-stream;charset=UTF-8') {
      downloadData(data, decodeURI(headers['content-disposition']))
      return true
    }

    if (data.tag !== 0) {
      if(router.currentRoute.path.startsWith('/bom') || router.currentRoute.path.startsWith('/invoice')){
        if(data.info === 'Token无效'){
          store.dispatch("user/logout").then(() => {
            router.replace({ path: "/login/bom" })
            return true
          });
        }
      }else{
        if(data.info === 'Token无效'){
          store.dispatch("user/logout").then(() => {
            router.replace({ path: "/login" })
            return true
          });
        }
      }
      
      Message({
        message: data.info ==='Token无效' ? '登录已失效，请重新登录' : (data.info || 'Error'),
        type: 'error',
        duration: 3000
      })
      if (data.code === "A0230") {
        store.dispatch("user/logout").then(() => {
          router.replace({ path: "/login" })
          return true
        });
      }
      return Promise.reject(new Error(data.info || 'Error'))
    } else {
      return data || {}
    }
  },
  error => {
    Message({
      message: error.message,
      type: 'error',
      duration: 3000
    })
    return Promise.reject(error)
  }
)

export default service
